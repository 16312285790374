import MGSurface from "@/app/app-components/MGSurface/MGSurface";
import { useTranslation } from "react-i18next";
import "./style.css";
import { MGHeading1 } from "@/components/typography";
import { getAMPMTime } from "@/app/hooks/useDateFormattingUtils";

export const WelcomeSplash = ({ nextClass, currentUser }) => {
  const { t } = useTranslation();

  return (
    <MGSurface>
      <div className="mg-home-welcome">
          <h1 className="heading-jumbo">
            {t("Welcome back,")}
            <br />
            {currentUser?.firstname}!
          </h1>

          <br />
          <br />
          

        {nextClass && (
            <div>
              <MGHeading1>{t("Your next class starts at {{%s}}", {"%s": getAMPMTime(nextClass?.start_time)})}</MGHeading1>
            </div>
          )}
      </div>
    </MGSurface>
  );
};
