import { MGIcon } from "@/components/icons";
import PropTypes from "prop-types";
import "./style.css";
import { useMGTranslate } from "@/components/translations/useMGTranslate";

export const MGMiniNotification = ({ id, message, type, onClose }) => {
  let cssClassNames = [
    "mg-notification",
    `mg-notification-${type}`,
    "--mg-effect-shadow-level1",
    "mg-notification-mini",
  ];

  const [t] = useMGTranslate();

  const iconSize = 16;

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <div className={cssClassNames.join(" ")} data-id={id}>
      <div className="mg-notification__message">
        <div className={`mg-notification__icon`}>
          <span className={`mg-notification__icon-${type}`}>
            <MGIcon size={iconSize} icon={type} />
          </span>
          <p style={{ fontSize: "12px" }}>{message}</p>
        </div>
      </div>
      <div className="mg-notification__dismiss" onClick={handleClose} style={{ fontSize: "12px" }}>
        {t("Close")}
      </div>
    </div>
  );
};

MGMiniNotification.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  onClose: PropTypes.func,
};

MGMiniNotification.defaultProps = {
  type: "info",
  message: "This is a notification",
};

export default MGMiniNotification;
