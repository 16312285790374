import { useEffect } from "react";
// import { useNavigate } from "react-router";
import { useApolloClient } from "@apollo/client";
import { isLoggedInVar } from "@/cache";
import config from "@/config";

export const LogoutPage = () => {
  // const navigate = useNavigate();

  const client = useApolloClient();

  const logout = async () =>{
    client.resetStore();

    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");

    isLoggedInVar(false);
    fetch(
        `${config.API_BASE_URL}/logoutuser`, 
        {
            method:"POST", 
            headers: {"Content-type": "application/json; charset=UTF-8"},
            credentials: "include",
            // credentials: "same-origin",
            mode: "cors",

        }
    ).finally(()=>{
      window.location.href = `${config.ADMIN_DASHBOARD_HOST}/#/logout`;
    })
  }

  useEffect(() => {
    // clear cache here https://www.apollographql.com/docs/react/networking/authentication
    logout()
    // window.location.href = `https://${Config.ADMIN_DASHBOARD_HOST}`;

    //navigate("/login");
  });

  return <></>;
};

export default LogoutPage;
