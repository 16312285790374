import { useMGTranslate } from "@/components/translations/useMGTranslate";
import "./style.css";
import PropTypes from "prop-types";

export const MGDaysPicker = ({ disabled, days, onChange, formikField }) => {
  let displayDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  const [t] = useMGTranslate();

  function onClickHandler(day) {
    let newSelected = days.slice().map((day) => {
      return day.toLowerCase();
    });

    if (disabled) return;
    if (days.length == 0) {
      newSelected.push(day);
    } else {
      let exists = days.filter((d) => d.toLowerCase() == day.toLowerCase()).length == 1;
      if (!exists) {
        newSelected.push(day);
      } else {
        newSelected.splice(days.indexOf(day), 1);
      }
    }
    if (typeof onChange === "function") {
      onChange(newSelected);
    }
  }

  function isSelected(day) {
    for (const d of days) {
      if (d.toLowerCase() == day.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  return (
    <div {...formikField} className={`--mg-days-picker  ${disabled ? "--mg-days-picker-disabled" : ""}`}>
      <ul>
        {displayDays.map((day) => (
          <li
            key={day}
            className={`${isSelected(day) ? "active" : ""}`}
            onClick={() => {
              onClickHandler(day);
            }}
          >
            {" "}
            {t(`_${day}`)}{" "}
          </li>
        ))}
      </ul>
    </div>
  );
};

MGDaysPicker.propTypes = {
  disabled: PropTypes.bool,
  days: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  formikField: PropTypes.any,
};

MGDaysPicker.defaultProps = {};

export default MGDaysPicker;
