import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTransferStudents } from "../../useTransferStudents";
import ClassStartModal from "../components/ClassStartModal";

import { useClassStart } from "./useClassStart";
import { useNavigate } from "react-router";

// named export for Testing
export const ClassStartModalContainer = ({
  classId,
  screenShareMode,
  duration,
  onSuccess,
  onFailure,
  onCancel,
  className,
}) => {
  if (!classId) return null;

  const [notification] = useNotification();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onSuccessHandler = (data) => {
    if (typeof onSuccess === "function") {
      transferStudents({
        classId: classId,
        device_uuids: data?.startClass?.startClassData.already_in_session_devices,
        onSuccess: () => {
          notification.success({ message: t("Students transferred successfully."), tiny: true });
          navigate(`/teach/class/${classId}`);
        },
        onFailure: () => {
          notification.error({
            message: t("Failed to transfer students to this class."),
          });
          navigate(`/teach/class/${classId}`);
        },
        onCancel: () =>{
          navigate(`/teach/class/${classId}`);
        }
      });
      
      
      onSuccess(data);
    }
    //If there are no students to transfer, navigate to teach page immediately 
    if(data?.startClass?.startClassData?.already_in_session_devices?.length < 1){
      navigate(`/teach/class/${classId}`);
    }  
    
  };

  const { loadingData, classDurations, modes, classContent, submitHandler } = useClassStart({
    classId,
    onSuccess: onSuccessHandler,
    onFailure,
  });

  let classDuration;
  let customDuration;

  if (!duration) {
    classDuration = 10;
    customDuration = null;
  } else {
    const nonCustomDurationFound = classDurations.filter((dur) => {
      return dur.value === duration;
    });
    if (!nonCustomDurationFound || nonCustomDurationFound.length < 1) {
      classDuration = 0;
      customDuration = duration;
    } else {
      classDuration = duration;
      customDuration = null;
    }
  }

  

  const [transferStudents] = useTransferStudents();

  return (
    <>
      <ClassStartModal
        isShowing={classId && classId !== ""}
        loading={loadingData}
        classDuration={classDuration}
        customDuration={customDuration}
        screenShareMode={screenShareMode}
        classDurations={classDurations}
        modes={modes}
        classContent={classContent}
        onSubmit={submitHandler}
        onCancel={onCancel}
        className={className}
      />
    </>
  );
};

ClassStartModalContainer.propTypes = {
  classId: PropTypes.string,
  screenShareMode: PropTypes.string,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ClassStartModalContainer;
