import "./style.css";
import classNames from "classnames";
import PropTypes from "prop-types";

import { MGIcon } from "@/components/icons";
import { MGMenu, MGMenuItem, MGMenuTrigger } from "@/components/menu";
import { MGSpacing } from "@/components/spacing";
import { useRef } from "react";
import { manualRoleChangeVar } from "@/cache";

import MGAvatar from "@/components/avatar/MGAvatar";
import { MGButton, MGIconButton } from "@/components/buttons";
import { useTranslation } from "react-i18next";

export const MGRolesPicker = ({ currentUser, schoolUsers, roles, onLogout, onRoleChange }) => {
  const menuRef = useRef();
  const schoolRef = useRef();

  const { t } = useTranslation();

  const onRoleChangeHandler = (event) => {
    manualRoleChangeVar(true);
    if (typeof onRoleChange == "function") {
      onRoleChange(event);
    }
  };

  const onSchoolChangeHandler = (event) => {
    if (typeof onRoleChange == "function") {
      onRoleChange(event.role_id, event.school_id);
    }
  };
  return (
    <>
      <div className="--mg-roles-picker">
        <div className={`--mg-roles-picker_schools ${classNames({ active: schoolUsers?.length > 1 })} `}>
          <MGMenuTrigger ref={schoolRef}>
            <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <MGSpacing size={"sm"} />
              <MGAvatar bordered={false} letter={currentUser?.school?.name} isSmall={true} />
              <MGSpacing size={"md"} />
              {currentUser?.school?.school_type?.id == "1" && <MGIcon icon={"school"} />} {/* School */}
              {currentUser?.school?.school_type?.id == "2" && <MGIcon icon={"district"} />} {/* District */}
              {currentUser?.school?.school_type?.id == "3" && <MGIcon icon={"corporate"} />} {/* Corporate */}
              {currentUser?.school?.school_type?.id == "4" && <MGIcon icon={"partner"} />} {/* Partner */}
              <MGSpacing size={"md"} />
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {currentUser?.school?.name}
              </span>
              <MGSpacing size={"sm"} />
              {/* <MGFlexBoxSpacer /> */}
            </div>
          </MGMenuTrigger>
        </div>
        {schoolUsers?.length > 1 && (
          <MGMenu trigger={schoolRef}>
            {schoolUsers?.map((user, index) => (
              <div key={index}>
                {!user.is_current && (
                  <MGMenuItem>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "80%",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <MGSpacing size={"sm"} />
                        {user?.school?.school_type?.id == "1" && <MGIcon icon={"school"} />} {/* School */}
                        {user?.school?.school_type?.id == "2" && <MGIcon icon={"district"} />} {/* District */}
                        {user?.school?.school_type?.id == "3" && <MGIcon icon={"corporate"} />} {/* Corporate */}
                        {user?.school?.school_type?.id == "4" && <MGIcon icon={"partner"} />} {/* Partner */}
                        <MGSpacing size={"md"} />
                        <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {user?.school?.name == "Parent Placeholder" ? "Parent Dashboard" : user?.school?.name}
                        </span>
                        <MGSpacing size={"sm"} />
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {user.role_id == "1" && (
                          <MGIconButton variant="primary" onClick={() => onSchoolChangeHandler(user)} icon={"admin"} />
                        )}{" "}
                        {/* Admin */}
                        {user.role_id == "2" && (
                          <MGIconButton variant="primary" onClick={() => onSchoolChangeHandler(user)} icon={"admin"} />
                        )}{" "}
                        {/* Super */}
                        {user.role_id == "3" && (
                          <MGIconButton
                            variant="primary"
                            onClick={() => onSchoolChangeHandler(user)}
                            icon={"teacher"}
                          />
                        )}{" "}
                        {/* Teacher */}
                        {user.role_id == "4" && (
                          <MGIconButton variant="primary" onClick={() => onSchoolChangeHandler(user)} icon={"parent"} />
                        )}{" "}
                        {/* Parent */}
                      </div>
                    </div>
                  </MGMenuItem>
                )}
              </div>
            ))}
          </MGMenu>
        )}
        <div className="--mg-roles-picker_divider"></div>
        <div className="--mg-roles-picker_roles" ref={menuRef}>
          <MGMenuTrigger>
            {currentUser?.role_id == "1" && <MGIcon icon={"admin"} />} {/* Admin */}
            {currentUser?.role_id == "2" && <MGIcon icon={"admin"} />} {/* Super */}
            {currentUser?.role_id == "3" && <MGIcon icon={"teacher"} />} {/* Teacher */}
            {currentUser?.role_id == "4" && <MGIcon icon={"parent"} />} {/* Parent */}
          </MGMenuTrigger>
        </div>
        <MGMenu trigger={menuRef}>
          <>
            {roles?.map((role, index) => (
              <MGMenuItem key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {role.id == "1" && <MGIcon icon={"admin"} onClick={() => onRoleChangeHandler(role.id)} />}{" "}
                  {/* Admin */}
                  {role.id == "2" && <MGIcon icon={"admin"} onClick={() => onRoleChangeHandler(role.id)} />}{" "}
                  {/* Super */}
                  {role.id == "3" && <MGIcon icon={"teacher"} onClick={() => onRoleChangeHandler(role.id)} />}{" "}
                  {/* Teacher */}
                  {role.id == "4" && <MGIcon icon={"parent"} onClick={() => onRoleChangeHandler(role.id)} />}{" "}
                  {/* Parent */}
                </div>
              </MGMenuItem>
            ))}
          </>

          <MGMenuItem>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MGIcon icon={"logout"} />
              <MGButton
                onClick={() => {
                  if (typeof onLogout === "function") {
                    onLogout();
                  }
                }}
                variant={"tertiary"}
              >
                {t("Logout")}
              </MGButton>
            </div>
          </MGMenuItem>
        </MGMenu>
        {/* </div> */}
      </div>
      <MGSpacing size={"sm"} />
    </>
  );
};

MGRolesPicker.propTypes = {
  currentUser: PropTypes.object,
  schoolUsers: PropTypes.any,
  roles: PropTypes.any,
  onLogout: PropTypes.func,
};

export default MGRolesPicker;
